import "@influencemachinesllc/webflow-shared-components/src/components/global.css";
import { setHideHeader } from './src/services/header'
import { setHideFooter } from './src/services/footer'

export const onPreRouteUpdate = ({ location }) => {
    if(location.search.includes('hideHeader=true'))
        setHideHeader(true)
    else setHideHeader(false)

    if(location.search.includes('hideFooter=true'))
        setHideFooter(true)
    else setHideFooter(false)
}